<template>
  <div class="info">
    <div>
        <el-image fit="contain" :src="imageUrl" style="width:100%;height:100%"></el-image>
    </div>
    <div class="meng">
        <el-image fit="contain" style="width:100%;height:100%" src="http://www.yzwhedu.com/youth/xcxImgs/meng.png"></el-image>
    </div>
  

  </div>
</template>

<script>
import bookApi from '../api/book.js'

export default {
  data() {

    return {
      imageUrl: "http://www.yzwhedu.com/youth/xcxImgs/meng.png",
    }
  },
  // 示例 url  http://localhost:8080/appraisal?bookId=2200&type=2   type:1,证书url 2，own证书url
  created(){
    let bookId =  this.$route.query.bookId
    let type =  this.$route.query.type
    if (type > null && bookId > null){
        this.getbook(bookId,type)
    }else{
        this.$message.error('参数错误！请重新扫码查询');
    }
  },
  methods: {
    getbook(bookId,type) {
        let that = this
      bookApi.bookMask( bookId , type).then(res => {       
        if ( res.data.success ) {
           
            that.imageUrl = res.data.data.url
           
        } else{
            that.$message.error('未找到该证书');

        } 
      }).catch(function (error) {})
    },


  },
  
}
</script>

<style scoped>
.info {
  width: 100%;
  position: relative;
  /* height: 100px; */
 
}

.meng{
    width: 100%;
    position: absolute;
    top: 0;
}


</style>>