import request from '@/utils/request'

export default {
  bookMask(bookId,type) {
    return request({
      url: `/youth/book/bookMask/${bookId}/${type}`,
      method: 'get',
    
    })
  },

  checkBook(form) {
    return request({
      url: `/youth/book/checkBook`,
      method: 'post',
      data: form
    
    })
  },
  
}
